import type {ChangeEvent} from 'react';
import {useEffect, useState} from 'react';

import Region from '@/components/pages/oberlo/icons/Region';
import Icon from '@/components/base/elements/Icon/Icon';
import {IconEnum} from '@/enums';

import {REGION_OPTIONS} from '../constants';

export default function SelectRegion() {
  const [selectedRegion, setSelectedRegion] = useState('English');

  useEffect(() => {
    const location = window?.location?.pathname.split('/')[1];
    const currentLanguage = REGION_OPTIONS.filter(
      (language) => location === language?.langCode && location !== 'oberlo',
    )[0];
    if (currentLanguage) {
      setSelectedRegion(currentLanguage?.lang);
    }
  }, []);

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectedRegion(selectedValue);
    const url = new URL(window.location.href, 'https://www.shopify.com');
    window.location.href = `${url.origin}${
      REGION_OPTIONS.filter(({lang}) => lang === selectedValue)[0].url
    }`;
  };

  return (
    <div className="mt-8">
      <div className="flex items-center">
        <label
          htmlFor="footer-lang-select-footer"
          className="pointer-events-none relative pt-3"
          aria-label="Select Language"
        >
          <Region />
          <span className="block absolute top-1/2 transform -translate-y-1/2 left-48 pointer-events-none">
            <Icon
              icon={IconEnum.Chevron}
              options={{
                orientation: 'down',
              }}
              size={18}
            />
          </span>
        </label>

        <select
          id="footer-lang-select-footer"
          className="pr-10 py-2 pl-12 bg-gray-50 bg-opacity-20 rounded appearance-none max-w-full hover:shadow-outline-violet focus:shadow-outline-violet focus:text-white"
          value={selectedRegion}
          onChange={handleRegionChange}
        >
          {REGION_OPTIONS.map((option) => (
            <option
              key={option.lang}
              value={option.lang}
              className="text-black"
            >
              {option.lang}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
